<template>
  <div class="gift-card-card">
    <ul class="nav flex-column gift-card-info-list">
      <li class="info-list-item gift-code">
        <div class="label">{{ $t('code')}}:</div>
        <span class="value">{{ giftCard.code }}</span>
      </li>
      <!-- <li class="info-list-item gift-enabled">
        <span class="label">{{ $t('enabled')}}</span>
        <span class="value">{{ giftCard }}</span>
      </li> -->
      <li class="info-list-item gift-status">
        <div class="label">{{ $t('status')}}:</div>
        <span class="value">{{ giftCard.state === 1 ? $t('available') : $t('not-available')}}</span>
      </li>
      <li class="info-list-item gift-credit">
        <div class="label">{{ $t('credit')}}:</div>
        <span class="value">{{ giftCard.balance | price($store) }}</span>
      </li>
      <li class="info-list-item gift-expire">
        <div class="label">{{ $t('expire')}}:</div>
        <span class="value">{{ expireAt }}</span>
      </li>
    </ul>
  </div>
</template>

<script>
import dayjs from 'dayjs'

export default {
  nama: 'CardGiftCard',
  props: {
    giftCard: {
      type: Object,
      required: true
    }
  },
  computed: {
    expireAt () {
      if (this.giftCard.expire_at) {
        return dayjs(this.giftCard.expire_at).format('DD/MM/YYYY')
      }
      return this.$t('not-specified')
    }
  }
}
</script>
