<template>
  <div class="cart-summary-info">
    <cms-content :id="infoBlockID" />
    <absolute-loader :showLoader="showLoader" />
  </div>
</template>

<script>
import CmsContent from '@/components/CmsContent'
import AbsoluteLoader from 'theme/components/AbsoluteLoader/AbsoluteLoader'

export default {
  name: 'SummaryInfo',
  data () {
    return {
      infoBlockID: 'cart-summary-info',
      showLoader: false
    }
  },
  mounted () {
    if (process.client) {
      const _this = this
      this.showLoader = true
      return this.$store.dispatch('cms/loadContent', _this.infoBlockID)
        .then(() => {
          _this.listenerForAccordion()
          _this.listenerForClick()
        })
        .finally(() => { _this.showLoader = false })
    }
  },
  methods: {
    listenerForClick () {
      $(document).on('click', '.bt-chat', function (e) {
        window.zE.activate && window.zE.activate()
        // document.querySelector('iframe#launcher').contentWindow.document.querySelector('button').click()
      })
    },
    listenerForAccordion () {
      const _this = this
      $(document).on('click', '.cart-summary-info .custom-accordion .pagebuilder-button-link', function (e) {
        let hasLink = $(this).attr('href')
        if (hasLink && hasLink.startsWith('#')) {
          e.preventDefault()
          e.stopPropagation()
          hasLink = hasLink.replace('#', '')
          _this.$store.commit('ui/showLoader')
          return _this.$store.dispatch('cms/loadContent', hasLink)
            .then(() => {
              const componentToLoad = import('@/components/CmsContent')
              return _this.$store.dispatch('ui/loadGenericModalComponent', {
                component: componentToLoad,
                props: {
                  id: hasLink
                },
                modalProps: {
                  modalClass: 'checkout-faq bcm-modal',
                  hideHeader: true,
                  okOnly: true,
                  okTitle: _this.$t('checkout.back-to-checkout')
                }
              })
            })
            .finally(() => {
              _this.$store.commit('ui/hideLoader')
            })
        }
      })
    }
  },
  components: {
    CmsContent,
    AbsoluteLoader
  }
}
</script>
